<template>
  <div class="foot">
    <div class="content">
      <ul>
        <li>
          <a href="javascript:return false;" class="blue">Brand Activity</a>
          <router-link to="/ShortVideoEN">Short Video</router-link>
          <router-link to="/InternationalEN">International</router-link>
          <router-link to="/CommunitiesEN">Communities</router-link>
          <router-link to="/ExchangeEN">Exchange</router-link>
          <router-link to="/OtherEventEN">OtherEvent</router-link>
        </li>
        <li>
          <a href="javascript:return false;" class="blue">About Shenzhen</a>
          <router-link to="/AboutEN">Shenzhen Overview</router-link>
          <router-link to="/IndustriesEN">Industries</router-link>
          <router-link to="/InnovationEN">Innovation</router-link>
          <router-link to="/GreenEN">Green Development</router-link>
          <router-link to="/MoreEN">More</router-link>
        </li>
        <li>
          <a href="javascript:return false;" class="blue">The ADVENTURE</a>
          <router-link to="/JustSZEN">Just shenzhen</router-link>
          <router-link to="/WholeCityEN">Whole-City <br> Discovery Route</router-link>
          <router-link to="/CultureCreativeEN">Culture & Creative Route</router-link>
          <router-link to="/EcoDiscoveryEN">Eco-Discovery Route</router-link>
          <router-link to="/ChungYingStreetEN">Chung Ying Street Route</router-link>
        </li>
        <!-- <li>
          <a href="javascript:return false;" class="blue">The BUZZ</a>
          <a href="http://www.wicco.net/en-US/">WICCO</a>
          <router-link  to="/EventsEN">The Focus</router-link>
          <router-link to="/ActivitiesEN">Activities</router-link>
        </li> -->
        <li>
          <a href="javascript:return false;" class="blue">The LIFE</a>
          <router-link to="/PoliciesEN">Start-up Policies</router-link>
        </li>
        <li>
          <a href="javascript:return false;" class="blue">The NETWORK</a>
          <router-link to="/AccountsEN">Cities</router-link>
          <router-link to="/FriendshipEN">Friendship Cities</router-link>
        </li>
        <li>
          <a href="javascript:return false;" class="blue">The WEBSITE</a>
          <router-link to="/AboutUsEN">About Us</router-link>
        </li>
        <li style="width: 130px;text-align: left">
          <a href="javascript:return false;" class="green">Links</a>
          <a href="http://www.sz.gov.cn/cn/">Shenzhen Government</a>
          <a href="http://www.szfao.gov.cn/">Shenzhen Foreign Affairs Office</a>
          <a href="http://www.eyeshenzhen.com/">EYESHENZHEN</a>
        </li>
      </ul>
      <div class="beian">
        <div class="mt1"><img src="../assets/images/common/logo.png"></div>
        <!-- <div class="mt2"><img src="../assets/images/common/foot_p1.png"></div> -->
        <div class="mt3">
          <a style="color: #fff" href="http://beian.miit.gov.cn">粤ICP备17121829号</a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
    }
  }
}
</script>

<style scoped>
.foot{width:100%; height:337px;background:url('../assets/images/common/foot_bg.jpg') center no-repeat;background-size: cover; position: relative; overflow: hidden;}
.foot .content{width:1122px; height: auto; position:relative;line-height:0px; margin:  0 auto; }
.foot .content ul{width: 860px; height:auto; overflow: hidden; margin-top: 60px; float: left; text-align: left;}
.foot .content ul li{min-width:60px;height: auto; float: left; margin-left:20px;}
.foot .content ul li:first-child{margin-left:0px;}
.foot .content ul li a{font-size: 14px; color: #fff; line-height: 32px; width: 100%; display: block;}
.foot .content ul li a.blue{color: #01b7cd;}
.foot .content ul li a.green{color: #a5cd39;}
.foot .content ul li a:hover{color: #cd0127;}
.foot .content ul li a:first-child:hover{color: #01b7cd;}
.foot .content .beian{width: 200px;  float: right;}
.foot .content .beian .mt1{margin-top: 47px;}
.foot .content .beian .mt2{margin-top: 47px;}
.foot .content .beian .mt3{font-size: 14px; line-height: 30px; color: #fff; margin-top: 110px;}
</style>
