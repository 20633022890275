import Vue from 'vue'
import Vuex from 'vuex'
import i18n from './i18n/i18n'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    userId: '',
    userName: '',
    isMobileSize: false
  },
  mutations: {
    saveId (state, newVal) {
      state.userId = newVal
    },
    saveName (state, newVal) {
      state.userName = newVal
    },
    setIsMobileSize(state, newVal) {
      state.isMobileSize = newVal
    }
  },
  actions: {

  },
  getters: {
    devicePathStr(state) {
      return state.isMobileSize ? '-m' : ''
    },
    langPathStr() {
      return i18n.locale === 'en' ? '-e' : ''
    },
    headerComName(state) {
      if (i18n.locale === 'en') {
        return state.isMobileSize ? 'ivheaderae' : 'vheadere'
      } else {
        return state.isMobileSize ? 'ivheadera' : 'vheader'
      }
    },
    footerComName(state) {
      if (i18n.locale === 'en') {
        return state.isMobileSize ? 'ivfootere' : 'vfootere'
      } else {
        return state.isMobileSize ? 'ivfooter' : 'vfooter'
      }
    },
    curHide() {
      return i18n.locale === 'en' ? 'en' : 'zh'
    }
  }
})
