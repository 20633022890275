import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
  mode: 'history',
  routes: [{
    path: '/Login',
    name: 'login',
    component: () => import(/* webpackChunkName: "city" */ './views/zh/Login.vue')
  },
  {
    path: '/LoginEN',
    name: 'logine',
    component: () => import(/* webpackChunkName: "city" */ './views/en/Login.vue')
  },
  {
    path: '/',
    name: 'city',
    component: () => import(/* webpackChunkName: "city" */ './views/zh/CityNew.vue')
  },
  {
    path: '/EN',
    name: 'citye',
    component: () => import(/* webpackChunkName: "citye" */ './views/zh/CityNew.vue')
  },
  {
    path: '/Policies',
    name: 'cityD',
    component: () => import(/* webpackChunkName: "cityD" */ './views/zh/cityD.vue')
  },
  {
    path: '/PoliciesEN',
    name: 'cityDe',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "cityDe" */ './views/en/cityD.vue')
  },
  {
    path: '/About',
    name: 'cityA1',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "CityA1" */ './views/zh/CityA1.vue')
  },
  {
    path: '/AboutEN',
    name: 'cityA1e',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "CityA1e" */ './views/en/CityA1.vue')
  },
  {
    path: '/Industries',
    name: 'cityA2',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "CityA2" */ './views/zh/CityA2.vue')
  },
  {
    path: '/IndustriesEN',
    name: 'cityA2e',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "CityA2e" */ './views/en/CityA2.vue')
  },
  {
    path: '/Innovation',
    name: 'cityA3',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "CityA3" */ './views/zh/CityA3.vue')
  },
  {
    path: '/InnovationEN',
    name: 'cityA3e',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "CityA3e" */ './views/en/CityA3.vue')
  },
  {
    path: '/Green',
    name: 'cityA4',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "CityA4" */ './views/zh/CityA4.vue')
  },
  {
    path: '/GreenEN',
    name: 'cityA4e',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "CityA4e" */ './views/en/CityA4.vue')
  },
  {
    path: '/More',
    name: 'cityA6',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "CityA6" */ './views/zh/CityA6.vue')
  },
  {
    path: '/MoreEN',
    name: 'cityA6e',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "CityA6e" */ './views/en/CityA6.vue')
  },
  {
    path: '/Accounts',
    name: 'cityE1',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "CityE1" */ './views/zh/CityE1.vue')
  },
  {
    path: '/AccountsEN',
    name: 'cityE1e',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "CityE1e" */ './views/en/CityE1.vue')
  },
  {
    path: '/Friendship',
    name: 'cityE2',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "CityE2" */ './views/zh/CityE2.vue')
  },
  {
    path: '/FriendshipEN',
    name: 'cityE2e',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "CityE2e" */ './views/en/CityE2.vue')
  },
  {
    path: '/City',
    name: 'cityE3',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "CityE3" */ './views/zh/CityE3.vue')
  },
  {
    path: '/CityEN',
    name: 'cityE3e',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "CityE3e" */ './views/en/CityE3.vue')
  },
  {
    path: '/AboutUs',
    name: 'cityF1',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "CityF1" */ './views/zh/CityF1.vue')
  },
  {
    path: '/AboutUsEN',
    name: 'cityF1e',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "CityF1e" */ './views/en/CityF1.vue')
  },
  {
    path: '/News/:newId',
    name: 'cityE5',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "CityE5" */ './views/zh/CityE5.vue')
  },
  {
    path: '/NewsEN/:newId',
    name: 'cityE5e',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "CityE5e" */ './views/en/CityE5.vue')
  },
  {
    path: '/FCdetail',
    name: 'cityE4',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "CityE4" */ './views/zh/CityE4.vue')
  },
  {
    path: '/FCdetailEN',
    name: 'cityE4e',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "CityE4e" */ './views/en/CityE4.vue')
  },
  {
    path: '/JustSZ',
    name: 'cityB1',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "cityB1" */ './views/zh/CityB1.vue')
  },
  {
    path: '/JustSZEN',
    name: 'cityB1e',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "cityB1" */ './views/en/CityB1.vue')
  },
  {
    path: '/EcoDiscovery',
    name: 'cityB2',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "cityB3" */ './views/zh/CityB2.vue')
  },
  {
    path: '/EcoDiscoveryEN',
    name: 'cityB2e',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "cityB3" */ './views/en/CityB2.vue')
  },
  {
    path: '/CultureCreative',
    name: 'cityB3',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "cityB3" */ './views/zh/CityB3.vue')
  },
  {
    path: '/CultureCreativeEN',
    name: 'cityB3e',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "cityB3" */ './views/en/CityB3.vue')
  },
  {
    path: '/WholeCity',
    name: 'cityB4',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "cityB3" */ './views/zh/CityB4.vue')
  },
  {
    path: '/WholeCityEN',
    name: 'cityB4e',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "cityB3" */ './views/en/CityB4.vue')
  },
  {
    path: '/ChungYingStreet',
    name: 'cityB5',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "cityB5" */ './views/zh/CityB5.vue')
  },
  {
    path: '/ChungYingStreetEN',
    name: 'cityB5e',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "cityB5e" */ './views/en/CityB5.vue')
  },
  {
    path: '/Events',
    name: 'cityC',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "cityC" */ './views/zh/CityC.vue')
  },
  {
    path: '/EventsEN',
    name: 'cityCe',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "cityCe" */ './views/en/CityC.vue')
  },
  {
    path: '/Eventdetail',
    name: 'cityCC',
    component: () => import(/* webpackChunkName: "cityCC" */ './views/zh/CityCC.vue')
  },
  {
    path: '/EventdetailEN',
    name: 'cityCCe',
    component: () => import(/* webpackChunkName: "cityCC" */ './views/en/CityCC.vue')
  },
  {
    path: '/Activities',
    name: 'cityQ1',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "cityQ1" */ './views/zh/CityQ1.vue')
  },
  {
    path: '/ActivitiesEN',
    name: 'cityQ1e',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "cityQ1e" */ './views/en/CityQ1.vue')
  },
  {
    path: '/activitylist',
    name: 'cityQ2',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "cityQ2" */ './views/zh/CityQ2.vue')
  },
  {
    path: '/activitylistEN',
    name: 'cityQ2e',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "cityQ2e" */ './views/en/CityQ2.vue')
  },
  {
    path: '/sponsorlist',
    name: 'cityQ3',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "cityQ3" */ './views/zh/CityQ3.vue')
  },
  {
    path: '/sponsorlistEN',
    name: 'cityQ3e',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "cityQ3e" */ './views/en/CityQ3.vue')
  },
  {
    path: '/sponsor',
    name: 'cityQ4',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "cityQ4" */ './views/zh/CityQ4.vue')
  },
  {
    path: '/sponsorEN',
    name: 'cityQ4e',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "cityQ4e" */ './views/en/CityQ4.vue')
  },
  {
    path: '/activity',
    name: 'cityQ5',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "cityQ5" */ './views/zh/CityQ5.vue')
  },
  {
    path: '/activityEN',
    name: 'cityQ5e',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "cityQ5e" */ './views/en/CityQ5.vue')
  },
  {
    path: '/create',
    name: 'cityQ6',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "cityQ6" */ './views/zh/CityQ6.vue')
  },
  {
    path: '/createEN',
    name: 'cityQ6e',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "cityQ6e" */ './views/en/CityQ6.vue')
  },
  {
    path: '/user',
    name: 'cityG',
    redirect: '/user/index',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "cityG" */ '@/views/zh/CityG.vue'),
    children: [{
      path: 'index',
      name: 'cityG1',
      component: () => import(/* webpackChunkName: "cityG1" */ '@/views/zh/CityG1.vue')
    },
    {
      path: 'eventadmin',
      name: 'cityG2',
      component: () => import(/* webpackChunkName: "cityG1" */ '@/views/zh/CityG2.vue')
    },
    {
      path: 'attendees',
      name: 'cityG2A',
      component: () => import(/* webpackChunkName: "cityG2A" */ './views/zh/CityG2A.vue')
    },
    {
      path: 'signnote',
      name: 'cityG2b',
      component: () => import(/* webpackChunkName: "CityG2b" */ './views/zh/CityG2b.vue')
    },
    {
      path: 'participate',
      name: 'cityG3',
      component: () => import(/* webpackChunkName: "cityG1" */ './views/zh/CityG3.vue')
    },
    {
      path: 'collection',
      name: 'cityG4',
      component: () => import(/* webpackChunkName: "cityG1" */ './views/zh/CityG4.vue')
    },
    {
      path: 'sponsorcollection',
      name: 'cityG5',
      component: () => import(/* webpackChunkName: "cityG1" */ './views/zh/CityG5.vue')
    },
    {
      path: 'authentication',
      name: 'cityG6',
      component: () => import(/* webpackChunkName: "cityG1" */ './views/zh/CityG6.vue')
    },
    {
      path: 'profile',
      name: 'cityG7',
      component: () => import(/* webpackChunkName: "cityG1" */ './views/zh/CityG7.vue')
    },
    {
      path: 'changepwd',
      name: 'cityG8',
      component: () => import(/* webpackChunkName: "cityG1" */ './views/zh/CityG8.vue')
    }
    ]
  },
  {
    path: '/userEN',
    name: 'cityGe',
    redirect: '/userEN/indexEN',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "cityGe" */ '@/views/en/CityG.vue'),
    children: [{
      path: 'indexEN',
      name: 'cityG1e',
      component: () => import(/* webpackChunkName: "cityG1e" */ '@/views/en/CityG1.vue')
    },
    {
      path: 'eventadminEN',
      name: 'cityG2e',
      component: () => import(/* webpackChunkName: "cityG1e" */ '@/views/en/CityG2.vue')
    },
    {
      path: 'attendeesEN',
      name: 'cityG2Ae',
      component: () => import(/* webpackChunkName: "cityG2Ae" */ './views/en/CityG2A.vue')
    },
    {
      path: 'signnoteEN',
      name: 'cityG2be',
      component: () => import(/* webpackChunkName: "CityG2be" */ './views/en/CityG2b.vue')
    },
    {
      path: 'participateEN',
      name: 'cityG3e',
      component: () => import(/* webpackChunkName: "cityG3e" */ './views/en/CityG3.vue')
    },
    {
      path: 'collectionEN',
      name: 'cityG4e',
      component: () => import(/* webpackChunkName: "cityG4e" */ './views/en/CityG4.vue')
    },
    {
      path: 'sponsorcollectionEN',
      name: 'cityG5e',
      component: () => import(/* webpackChunkName: "cityG5e" */ './views/en/CityG5.vue')
    },
    {
      path: 'authenticationEN',
      name: 'cityG6e',
      component: () => import(/* webpackChunkName: "cityG6e" */ './views/en/CityG6.vue')
    },
    {
      path: 'profileEN',
      name: 'cityG7e',
      component: () => import(/* webpackChunkName: "cityG7e" */ './views/en/CityG7.vue')
    },
    {
      path: 'changepwdEN',
      name: 'cityG8e',
      component: () => import(/* webpackChunkName: "cityG8" */ './views/en/CityG8.vue')
    }
    ]
  },
  {
    path: '/Register',
    name: 'cityZ1',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "CityZ1" */ './views/zh/CityZ1.vue')
  },
  {
    path: '/RegisterEN',
    name: 'cityZ1e',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "CityZ1e" */ './views/en/CityZ1.vue')
  },
  {
    path: '/Forget',
    name: 'cityZ2',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "CityZ2" */ './views/zh/CityZ2.vue')
  },
  {
    path: '/ForgetEN',
    name: 'cityZ2e',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "CityZ2e" */ './views/en/CityZ2.vue')
  },
  {
    path: '/ShortVideo',
    name: 'ShortVideo',
    meta: { title1: 'Short Video', title2: '品牌活动-短视频大赛', type: '1' },
    component: () => import(/* webpackChunkName: "CityH1" */ './views/zh/CityH1.vue')
  },
  {
    path: '/ShortVideoEN',
    name: 'ShortVideoe',
    meta: { title1: 'Short Video', title2: '', type: '1' },
    component: () => import(/* webpackChunkName: "CityH1" */ './views/zh/CityH1.vue')
  },
  {
    path: '/International',
    name: 'International',
    meta: { title1: 'International', title2: '品牌活动-国际化在身边', type: '2' },
    component: () => import(/* webpackChunkName: "CityH1" */ './views/zh/CityH1.vue')
  },
  {
    path: '/InternationalEN',
    name: 'Internationale',
    meta: { title1: 'International', title2: '', type: '2' },
    component: () => import(/* webpackChunkName: "CityH1" */ './views/zh/CityH1.vue')
  },
  {
    path: '/Communities',
    name: 'Communities',
    meta: { title1: 'Communities', title2: '品牌活动-国际社群活动', type: '3' },
    component: () => import(/* webpackChunkName: "CityH1" */ './views/zh/CityH1.vue')
  },
  {
    path: '/CommunitiesEN',
    name: 'Communitiese',
    meta: { title1: 'Communities', title2: '', type: '3' },
    component: () => import(/* webpackChunkName: "CityH1" */ './views/zh/CityH1.vue')
  },
  {
    path: '/Exchange',
    name: 'Exchange',
    meta: { title1: 'Exchange', title2: '品牌活动-留学生菁英交流营', type: '4' },
    component: () => import(/* webpackChunkName: "CityH1" */ './views/zh/CityH1.vue')
  },
  {
    path: '/ExchangeEN',
    name: 'Exchangee',
    meta: { title1: 'Exchange', title2: '', type: '4' },
    component: () => import(/* webpackChunkName: "CityH1" */ './views/zh/CityH1.vue')
  },
  {
    path: '/OtherEvent',
    name: 'OtherEvent',
    meta: { title1: 'Other Event', title2: '品牌活动-其他活动', type: '5' },
    component: () => import(/* webpackChunkName: "CityH1" */ './views/zh/CityH1.vue')
  },
  {
    path: '/OtherEventEN',
    name: 'OtherEvente',
    meta: { title1: 'Other Event', title2: '', type: '5' },
    component: () => import(/* webpackChunkName: "CityH1" */ './views/zh/CityH1.vue')
  },
  {
    path: '/VideoList',
    name: 'VideoList',
    meta: { type: '6' },
    component: () => import(/* webpackChunkName: "CityH1" */ './views/zh/CityH1.vue')
  },
  {
    path: '/VideoListEN',
    name: 'VideoListe',
    meta: { type: '6' },
    component: () => import(/* webpackChunkName: "CityH1" */ './views/zh/CityH1.vue')
  },
  {
    path: '/ActiveDetail/:detailId',
    name: 'ActiveDetail',
    meta: { listType: '/info' },
    component: () => import(/* webpackChunkName: "CityH2" */ './views/zh/CityH2.vue')
  },,
  {
    path: '/ActiveDetailEN/:detailId',
    name: 'ActiveDetaile',
    meta: { listType: '/info' },
    component: () => import(/* webpackChunkName: "CityH2" */ './views/zh/CityH2.vue')
  },
  {
    path: '/VideoDetail/:detailId',
    name: 'VideoDetail',
    meta: { listType: '/video/info' },
    component: () => import(/* webpackChunkName: "CityH2" */ './views/zh/CityH2.vue')
  },
  {
    path: '/VideoDetailEN/:detailId',
    name: 'VideoDetaile',
    meta: { listType: '/video/info' },
    component: () => import(/* webpackChunkName: "CityH2" */ './views/zh/CityH2.vue')
  },
  {
    path: '/Code',
    name: 'code',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "cityQ6" */ './views/zh/codeTicket.vue')
  },
  {
    path: '/CodeEN',
    name: 'codee',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "cityQ6e" */ './views/en/codeTicket.vue')
  },
  {
    path: '/Agreement',
    name: 'Agreement',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Agreement" */ './views/zh/Agreement.vue')
  },
  {
    path: '/AgreementEN',
    name: 'Agreemente',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Agreemente" */ './views/en/Agreement.vue')
  },
  {
    path: '/Policy',
    name: 'Policy',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Policy" */ './views/zh/policy.vue')
  },
  {
    path: '/PolicyEN',
    name: 'Policye',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Policye" */ './views/en/policy.vue')
  }],
  scrollBehavior (to, from, savedPosition) {
    // return 期望滚动到哪个的位置
    return {
      x: 0,
      y: 0
    }
  }
})
