
import zhLocale from 'element-ui/lib/locale/lang/zh-CN'

const cn = {
  message: {
    'hello': '你好，世界'
  },
  ...zhLocale
}

export default cn
